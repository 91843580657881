import {ModalMaker} from "../components/ModalMaker/ModalMaker";
import {TSuiButton} from "../components/TSuiButton";
import {endOfDay, parseDate, startOfDay} from "../shared/dateutils";
import {CreditType} from "../interfaces/credits";
import {TSuiBox} from "../components/TSuiBox";
import {TSuiTypography} from "../components/TSuiTypography";
import {TSuiInput} from "../components/TSuiInput";
import {useEffect, useState} from "react";
import {getData, postData} from "../api/api";
import {ClientEvent} from "clientevent";

interface Props {
  userId: string;
  creditId: string;
  onSave?: (credit: CreditType) => void;
  onRemove?: () => void;
  onClose?: () => void;
}

export function CreditEditor(props: Props) {
  const {userId, creditId, onSave, onClose, onRemove} = props;
  const [credit, setCredit] = useState<CreditType | undefined>();

  const saveCredit = () => {
    if (credit) {
      const {id, ...remaining} = credit;
      postData(`users/${userId}/credits/${creditId || ""}`, remaining)
        .then((results) => {
          if (results.status === 200) {
            ClientEvent.publish("SNACKBAR", "Credit saved");
            onSave && onSave(credit);
          }
        })
        .catch((err) => {
          ClientEvent.publish("SNACKBAR", `Error: ${err.message}`)
        })
    }
  }

  useEffect(() => {
    if (creditId) {
      getData(`users/${userId}/credits/${creditId}`)
        .then((results) => {
          if (results.status === 200 && results?.data?.credit) {
            setCredit(results.data.credit)
          }
        })
        .catch((err) => {
          ClientEvent.publish("SNACKBAR", `Error: ${err.message}`)
          props.onClose && props.onClose();
        })
    } else {
      setCredit({
        id: "new",
        credits: 0,
        activate: startOfDay(Date.now()).unix(),
        expires: endOfDay(Date.now()).unix(),
        bonus: false
      })
    }
    return () => {
      setCredit(undefined);
    }
  }, [creditId, userId]);

  const updateCredit = (key: string, value: any) => {
    setCredit((prevState) => ({
      ...prevState,
      [key]: value
    }));
  }

  return <ModalMaker open={!!credit}
                     title={`${creditId ? "Edit" : "Add"} Credit`}
                     actions={<>
                       <TSuiButton iconOnly={false}
                                   variant="gradient"
                                   color="info"
                                   title={"Save then Close dialog"}
                                   onClick={() => {
                                     saveCredit();
                                   }}
                       >
                         {creditId ? "Save" : "Add"}
                       </TSuiButton>

                       {/*{onRemove && creditId && (*/}

                       {/*  <TSuiButton iconOnly={false}*/}
                       {/*              variant="gradient"*/}
                       {/*              color="error"*/}
                       {/*              title={"Remove/Cancel Reservation"}*/}
                       {/*              onClick={() => onRemove()}*/}
                       {/*  >*/}
                       {/*    Remove*/}
                       {/*  </TSuiButton>*/}
                       {/*)}*/}

                       <TSuiButton iconOnly={false}
                                   variant="gradient"
                                   color="dark"
                                   title={"Close Dialog Without Saving"}
                                   onClick={() => onClose && onClose()}
                       >
                         Cancel
                       </TSuiButton>
                     </>}>
    {credit && (
      <TSuiBox p={0}>
        <TSuiBox p={0} display={"flex"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Activate Date
          </TSuiTypography>
          <TSuiInput type="date" placeholder="Date"
                     componentsProps={{input: {max: parseDate(credit.expires * 1000).format("YYYY-MM-DD")}}}
                     onChange={(e) => updateCredit("activate", parseDate(`${e.target.value} 00:00:00`).unix())}
                     value={parseDate(credit.activate * 1000).format("YYYY-MM-DD")}/>
        </TSuiBox>

        <TSuiBox p={0} display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Expires
          </TSuiTypography>
          <TSuiInput type="date" placeholder="Date"
                     componentsProps={{input: {min: parseDate(credit.activate * 1000).format("YYYY-MM-DD")}}}
                     onChange={(e) => updateCredit("expires", parseDate(`${e.target.value} 23:59:59`).unix())}
                     value={parseDate(credit.expires * 1000).format("YYYY-MM-DD")}/>
        </TSuiBox>

        <TSuiBox p={0} display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Credits
          </TSuiTypography>
          <TSuiInput type="number" placeholder="Credits"
                     onChange={(e) => updateCredit("credits", e.target.value)}
                     value={credit.credits}/>
        </TSuiBox>

        <TSuiBox p={0} display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Bonus
          </TSuiTypography>
          <TSuiInput type="checkbox" placeholder="" componentsProps={{input: {checked: credit.bonus}}}
                     onChange={() => updateCredit("bonus", !credit.bonus)}/>
        </TSuiBox>
      </TSuiBox>
    )}
  </ModalMaker>
}