import {RouteComponentProps} from "@reach/router";
import Card from "@mui/material/Card";
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import * as React from "react";
import {useState} from "react";
import {FormField} from "../../components/FormField";

interface Props extends RouteComponentProps {

}

interface FormData {
  id: string;
  today_date: string;
  arrival_time: string;
  company: string;
  project: string;
  equipment: string;
  completed_work: string;
  notes: string;
  vendor_work: string;
  special_equipment: string;
  safety_issues: boolean;
  kudos_concerns: string;
}

export function CollectionForm(props: Props) {
  const [userData, setFormData] = useState<Partial<FormData>>({});

  const userValHandler = (key: keyof FormData, val: string | number | boolean) => {
    // console.log(key, val)
    setFormData(prev => ({...prev, [key]: val}));
  };

  return <DashboardLayout>
    <Card>
      <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0}>
        <TSuiTypography variant="h5" fontWeight="medium">
          Job Site Daily Log
        </TSuiTypography>
        <TSuiTypography variant="body1" fontWeight="regular">
          <>
            Fill this form out <strong>completely</strong> at the end of each day before you clock out. Please be as
            detailed as possible.
          </>
        </TSuiTypography>
      </TSuiBox>
      <TSuiBox p={3} paddingTop={0}>
        <TSuiBox component="form" role="form">
          <TSuiBox display={"flex"}>
            <TSuiBox width={"50%"}>
              <FormField placeholder=""
                         label={"Today's Date"}
                         type="date"
                         mb={2}
                         value={userData?.today_date || ""}
                         onChange={(evt) => userValHandler("today_date", evt.target.value)}/>
            </TSuiBox>
            <TSuiBox width={"50%"}>
              <FormField placeholder=""
                         label={"Job Site Arrival"}
                         type="time"
                         mb={2}
                         value={userData?.arrival_time || ""}
                         onChange={(evt) => userValHandler("arrival_time", evt.target.value)}/>
            </TSuiBox>
          </TSuiBox>

          <FormField placeholder=""
                     label={"Company"}
                     type="select"
                     mb={2}
                     value={{
                       value: userData?.company || null,
                       label: userData?.company || "N/A"
                     }}
                     onChange={(evt: any) => {
                       userValHandler("company", evt.value)
                     }}
                     options={[null, "Premier", "Corkeen"].map((entry) => ({
                       value: entry,
                       label: entry === null ? "N/A" : entry
                     }))}
                     size="small"
          />

          <FormField placeholder=""
                     label={"Project/Job Name"}
                     type="select"
                     mb={2}
                     value={{
                       value: userData?.project || null,
                       label: userData?.project || "N/A"
                     }}
                     onChange={(evt: any) => {
                       userValHandler("project", evt.value)
                     }}
                     options={[null, "Project Name 1", "Project Name 2"].map((entry) => ({
                       value: entry,
                       label: entry === null ? "N/A" : entry
                     }))}
                     size="small"
          />

          <FormField placeholder=""
                     label={"Equipment used today."}
                     type="text"
                     multiline={true}
                     mb={2}
                     value={userData?.equipment || ""}
                     onChange={(evt) => userValHandler("equipment", evt.target.value)}/>

          <FormField placeholder=""
                     label={"What was completed on site today?"}
                     type="text"
                     multiline={true}
                     mb={2}
                     value={userData?.completed_work || ""}
                     onChange={(evt) => userValHandler("completed_work", evt.target.value)}/>

          <TSuiBox mb={2}>
            <FormField placeholder=""
                       label={"Anything of note"}
                       type="text"
                       multiline={true}
                       mb={0}
                       pb={0}
                       value={userData?.notes || ""}
                       onChange={(evt) => userValHandler("notes", evt.target.value)}/>
            <TSuiTypography variant={"caption"} m={0} p={0}>
              unexpected site conditions, other contractors, was there a
              delay, etc.
            </TSuiTypography>
          </TSuiBox>

          <TSuiBox mb={2}>
            <FormField placeholder=""
                       label={"Did you speak with any other vendors?"}
                       type="text"
                       multiline={true}
                       mb={0}
                       pb={0}
                       value={userData?.vendor_work || ""}
                       onChange={(evt) => userValHandler("vendor_work", evt.target.value)}/>
            <TSuiTypography variant={"caption"} m={0} p={0}>
              Conversations had with on-site contacts, clients stopping by, did you have to call the office, etc.
            </TSuiTypography>
          </TSuiBox>

          <FormField placeholder=""
                     label={"Do you need to bring any special tools or equipment from the yard tomorrow?"}
                     type="text"
                     multiline={true}
                     mb={0}
                     pb={0}
                     value={userData?.special_equipment || ""}
                     onChange={(evt) => userValHandler("special_equipment", evt.target.value)}/>


          <FormField placeholder=""
                     label={"Any safety incidents or near-misses that occurred onsite today?"}
                     type="select"
                     mb={2}
                     value={{
                       value: userData?.safety_issues || 'no',
                       label: userData?.safety_issues || "no"
                     }}
                     onChange={(evt: any) => {
                       userValHandler("safety_issues", evt.value === "yes")
                     }}
                     options={["no", "yes"].map((entry) => ({
                       value: entry,
                       label: entry === null ? "N/A" : entry
                     }))}
                     size="small"
          />

          <TSuiBox mb={2}>
            <FormField placeholder=""
                       label={"Leads/Install Techs: please share any kudos or concerns about your team today"}
                       type="text"
                       multiline={true}
                       mb={0}
                       pb={0}
                       value={userData?.kudos_concerns || ""}
                       onChange={(evt) => userValHandler("kudos_concerns", evt.target.value)}/>
            <TSuiTypography variant={"caption"} m={0} p={0}>
              Conversations had with on-site contacts, clients stopping by, did you have to call the office, etc.
            </TSuiTypography>
          </TSuiBox>

        </TSuiBox>
      </TSuiBox>
      <TSuiBox p={3} m={3}/>
    </Card>
  </DashboardLayout>
}