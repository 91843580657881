// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockReset from "@mui/icons-material/LockReset";
import Payment from "@mui/icons-material/Payment";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import DataTable from "../../components/DataTable";
import {useEffect, useState} from "react";
import {deleteData, getData, postData} from "../../api/api";
import {ClientEvent} from "clientevent";
import {DialogContentType, DialogControlPrompt} from "../../components/Dialog/DialogControl";
import {UserType} from "../../interfaces/users";
import {TSuiButton} from "../../components/TSuiButton";
import * as regex from "../../constants/regex";

interface Props extends RouteComponentProps {

}

export function Users(props: Props) {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserType[]>([]);

  const refreshTables = async () => {
    const data = await getData("users").then((response) => (response.data || {users: []})).catch(() => ({users: []}));
    setUsers(data.users)
  }

  const resetPassword = (value: string) => {
    ClientEvent.emit("DIALOG", {
      title: "Reset Password",
      type: "prompt",
      regex: regex.password,
      helperText: <TSuiBox display={"flex"} flexDirection={"column"}>
        <TSuiTypography variant="caption" color="text" fontWeight="regular">
          Requirements:
        </TSuiTypography>
        <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
          &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;at least 6 characters long
        </TSuiTypography>
        <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
          &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 uppercase letter
        </TSuiTypography>
        <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
          &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 lowercase letter
        </TSuiTypography>
        <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
          &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 number
        </TSuiTypography>
      </TSuiBox>,
      regexStopValues: ["Change"],
      buttons: ['Change', 'Cancel'],
      onClick: (btnValue: string | null, inputValue: string) => {
        // console.log(btnValue, inputValue)
        if (btnValue === "Change" && inputValue.length > 0) {
          postData(`users/${value || ""}/password`, {password: inputValue})
            .then(async (result) => {
              if (result.status === 200) {
                await navigate(`/settings/users`)
                ClientEvent.publish("SNACKBAR", "Password changed");
              } else {
                ClientEvent.publish("SNACKBAR", `Password NOT changed: ${result.statusText}`);
                console.log(result.statusText)
              }
            }).catch((err) => {
            ClientEvent.publish("SNACKBAR", `Password NOT changed: ${err.response.statusText}`);
            console.log(err.result.statusText)
          })
        } else {
          ClientEvent.publish("SNACKBAR", "Password NOT changed");
        }
      }
    } as DialogControlPrompt)
  }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove User",
      message: "Are you sure you want to remove this User?",
      buttons: ["Yes", "No"],
      onClick: (value2) => {
        if (value2 === "Yes") {
          deleteData(`users/${value}`)
            .then(async (response) => {
              await refreshTables();
            })
            .catch((err) => {
              ClientEvent.publish("SNACKBAR", err.message);
            });
        }
      }
    } as DialogContentType)
  }

  useEffect(() => {
    refreshTables();
  }, []);

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0} display={"flex"} justifyContent={"space-between"}>
          {/*<TSuiTypography variant="h5" fontWeight="medium">*/}
          {/*</TSuiTypography>*/}
          <TSuiTypography variant="h5" fontWeight="medium">
            Users
          </TSuiTypography>
          {/*<TSuiButton iconOnly={false}*/}
          {/*            variant="gradient"*/}
          {/*            color="info"*/}
          {/*            title={"Edit Name"}*/}
          {/*            onClick={() => navigate(`/settings/office/add`)}*/}
          {/*>*/}
          {/*  Add*/}
          {/*</TSuiButton>*/}
        </TSuiBox>
        <TSuiBox p={0}>
          <DataTable<UserType>
            // isSorted={true}
            canSearch={true}
            table={{
              columns: [{
                Header: "First Name",
                accessor: "first_name",
                width: "20%"
              }, {
                Header: "Last Name",
                accessor: "last_name",
                width: "20%",
              }, {
                Header: "Admin",
                accessor: "admin",
                width: "5%",
                Cell: ({cell: {value}}) => (<>{value ? "Yes" : "No"}</>)
              }, {
                Header: "Trained",
                accessor: "trained",
                width: "5%",
                Cell: ({cell: {value}}) => (<>{value ? "Yes" : "No"}</>)
              }, {
                Header: "Bill Frequency",
                accessor: "bill_freq",
                width: "15%",
                Cell: ({cell: {value}}) => (<>{value || "N/A"}</>)
              },
                {
                  Header: "Actions",
                  accessor: "id",
                  width: "20%",
                  align: "right",
                  disableSortBy: true,
                  Cell: ({cell: {value}}) => (
                    <>
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="info"
                                  title={"Edit User"}
                                  onClick={() => navigate(`/settings/users/${value}`)}>
                        <EditIcon/>
                      </TSuiButton>
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="warning"
                                  title={"Credits"}
                                  onClick={() => navigate(`/settings/users/${value}/credits`)}>
                        <Payment/>
                      </TSuiButton>
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="dark"
                                  title={"Reset Password"}
                                  onClick={() => resetPassword(value)}>
                        <LockReset/>
                      </TSuiButton>
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="error"
                                  title={"Remove"}
                                  onClick={() => removeConfirm(value)}>
                        <DeleteIcon/>
                      </TSuiButton>
                    </>
                  )
                }
              ],
              rows: users
            }}/>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
