import {useEffect, useState} from "react";

// react-router-dom components
import {RouteComponentProps} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import {deleteData, getData, postData} from "../../api/api";
import {EventType, fromEventTypeDTO} from "../../interfaces/events";
import {epochNow, parseDate} from "../../shared/dateutils";
import {ReservationEditor} from "../../modals/ReservationEditor";
import {ClientEvent} from "clientevent";
import {DialogContentType} from "../../components/Dialog/DialogControl";
import {useGlobalState} from "../../context/GlobalStateProvider";

interface Props extends RouteComponentProps {

}

export function AllReservations(props: Props) {
  const [resources, setResources] = useState<{ id: string, display: string, numRooms: number }[]>([]);
  const [editReservation, setEditReservation] = useState<string | undefined>();
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const [newReservation, setNewReservation] = useState<{
    start: number,
    end: number,
    office_id?: string,
    user_id: string
  } | undefined>();
  const [events, setEvents] = useState<EventType[]>([]);
  const {state: {configData}} = useGlobalState();

  const refreshList = async () => {
    const data = await getData("reservations/all").then((response) => (response.data || {
      offices: [],
      events: []
    })).catch(() => ({
      offices: [],
      events: []
    }));
    setResources(prev => data.offices);
    setEvents(prev => (data.events || []).map(fromEventTypeDTO));
  }

  const adminCheckout = async (reservationId: string) => {
    await postData(`reservations/all/${reservationId}/checkout`, {}).then(() => {
      ClientEvent.publish("SNACKBAR", "Checked out");
      setShowCheckout(false);
      setEditReservation(undefined);
      refreshList();
    });
  }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove Reservation",
      message: "Are you sure you want to remove (cancel) this registration?",
      buttons: ["Yes", "No"],
      onClick: (value2) => {
        if (value2 === "Yes") {
          deleteData(`reservations/${value}`)
            .then(async (response) => {
              setEditReservation(undefined)
              ClientEvent.publish("SNACKBAR", "Removed Successfully");
              await refreshList();
            })
            .catch((err) => {
              ClientEvent.publish("SNACKBAR", err.message);
            });
        }
      }
    } as DialogContentType)
  }

  const saveReservation = (reservation: any, cb?: () => void) => {
    const {id, ...rest} = reservation;
    postData(`reservations/${id || "add"}`, {...rest})
      .then(async (response) => {
        setEditReservation(undefined)
        setNewReservation(undefined);
        ClientEvent.publish("SNACKBAR", `${id ? "Modified" : "Added"} Reservation`);
        await refreshList();
      })
      .catch((err) => {
        cb && cb();
        ClientEvent.publish("SNACKBAR", `Error: ${err.response.data}`);
      });
  }

  useEffect(() => {
    refreshList();
  }, [])

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} height={"100% - 200px"} maxHeight={"100% - 200px"}>
          <FullCalendar
            plugins={[interactionPlugin, resourceTimelinePlugin]}
            height={"auto"}
            initialView="resourceTimeline"
            schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
            weekends={true}
            slotDuration={'00:15:00'}
            editable={true}
            timeZone={"UTC"}
            resources={[...resources.map(({display, ...resource}) => ({...resource, title: display}))]}
            events={events}
            nowIndicator={true}
            scrollTimeReset={false}
            slotMinTime={"07:00:00"}
            slotMaxTime={"18:00:00"}
            snapDuration={"00:30:00"}
            dayMinWidth={1}
            resourceAreaWidth={"15%"}
            eventMinWidth={1}
            slotMinWidth={5}
            selectable={true}
            selectMirror={true}
            resourceAreaHeaderContent={"Office"}
            scrollTime={epochNow().format("HH:MM:SS")}
            eventChange={(event) => {
              const newReservation = {
                id: event.event.id,
                start: parseDate(event.event.startStr).unix(),
                end: parseDate(event.event.endStr).unix(),
                office_id: event.event.getResources()[0].id,
                user_id: event.event.extendedProps.user_id
              }
              saveReservation(newReservation, () => {
                event.revert();
              });
            }}
            select={(dated) => {
              setNewReservation({
                start: (parseDate(dated.startStr).unix()),
                end: (parseDate(dated.endStr).unix()),
                office_id: dated.resource.id,
                user_id: configData.id
              });
            }}
            eventClick={(dated) => {
              // console.log("eventClick", dated)
              setEditReservation(dated.event.id);
              if (dated.event.extendedProps.status === "checked_in") {
                setShowCheckout(dated.event.extendedProps.status === "checked_in");
              } else if (dated.event.extendedProps.status === "reserved") {
                setShowCheckout(false);
              } else {
                setShowCheckout(false);
              }
            }}
            now={() => epochNow().toDate()}
            customButtons={{
              myCustomButton: {
                text: 'refresh',
                click: () => {
                  refreshList();
                }
              }
            }}
            headerToolbar={{
              left: 'title',
              center: '',
              right: 'prev,next today myCustomButton'
            }}
          />
        </TSuiBox>
      </Card>
      <ReservationEditor reservationId={editReservation}
                         newReservationData={newReservation}
                         onRemove={!showCheckout ? () => {
                           removeConfirm(editReservation)
                         } : undefined}
                         onClose={() => {
                           setEditReservation(undefined);
                           setNewReservation(undefined)
                         }}
                         onSave={(reservation) => {
                           // console.log(reservation)
                           saveReservation(reservation)
                         }}
                         onCheckout={showCheckout ? () => {
                           adminCheckout(editReservation);
                         } : undefined}
                         showUser/>
    </DashboardLayout>
  );
}
